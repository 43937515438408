<template>
  <div class="row">

    <div class="col-12">

      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">News List</h4>
          <div class="d-flex justify-content-start">
            <l-button
              v-if="$store.getters['auth/haveOneOfPermissions'](['news/clear-cache'])"
                      @click="openCacheModal"
                      nativeType="submit"
                      style="margin-bottom: 5px;"
                      type="danger" wide>Clear All News Cache
            </l-button>
            <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['news/create'])"
                        :to="'/news/create'"
                        class="btn btn-info btn-wd">
              Add New
              <span class="btn-label">
                      <i class="fa fa-plus"></i>
                  </span>
            </router-link>
          </div>

        </div>

        <!--    filters    -->
        <div class="row col-12 mb-2">
          <div class="col-md-4 col-sm-4">
            <fg-select
              size="large"
              filterable
              clearable
              placeholder="Select Option"
              :input-classes="'select-default'"
              :list="isFeaturedOptions"
              :listItemLabel="'label'"
              :listItemValue="'value'"
              v-model="filters.is_featured">
            </fg-select>
          </div>

          <div class="col-md-2 col-sm-4">
            <l-button class="mr-lg-1" @click="applyFilter()" type="info">Filter Result</l-button>
          </div>
        </div>
        <!--  end  filters    -->

        <div>
          <simple-general-data-table
            ref="table"
            :advanceSearchFilter="advanceSearchFilter"
            :method="'POST'"
            :row-key="'id'"
            :key="componentKey"
            @reorder="reorder($event)"
            :api-url="'news/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">
              <template slot-scope="scope">

                <div v-if="column.value === 'url'">
                    <span>
                        https:/www.lilibya.com/n/sl-{{scope.row['id']}}
                    </span>
                </div>
                <div v-else-if="column.value === 'category'">
                    <span v-if="scope.row['category']">
                      {{scope.row['category'].name}}
                    </span>
                </div>

                <span v-else>{{ scope.row[column.value] }}</span>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/haveOneOfPermissions'](['news/change-active-status']) ||
                                $store.getters['auth/checkAccessOwner']('restricted_own/news/change-active-status', props.row.creator_id)"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>

            <!-- <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="publish">
              <template slot-scope="scope">
                <a v-if="$store.getters['auth/haveOneOfPermissions'](['news/publish-facebook']) ||
                           $store.getters['auth/checkAccessOwner']('restricted_own/news/publish-facebook', scope.row.creator_id)"
                   v-tooltip.top-center="'Publish on facebook'"
                   class="btn-success btn-simple btn-link"
                   style="color:#2d88ff;font-size: 24px;cursor: pointer;margin-right: 5px"
                   @click="openPublishModal(scope.row.id, 'facebook', scope.row.facebook_publish_dates)"><i class="fab fa-facebook"></i></a>
                <a v-if="$store.getters['auth/haveOneOfPermissions'](['news/publish-twitter']) ||
                           $store.getters['auth/checkAccessOwner']('restricted_own/news/publish-twitter', scope.row.creator_id)"
                   v-tooltip.top-center="'Publish on twitter'"
                   class="btn-success btn-simple btn-link"
                   style="color:#1d9bf0;font-size: 24px;cursor: pointer"
                   @click="openPublishModal(scope.row.id, 'twitter', scope.row.twitter_publish_dates)"><i class="fab fa-twitter"></i></a>

              </template>
            </el-table-column> -->


            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['news/update']) ||
                                     $store.getters['auth/checkAccessOwner']('restricted_own/news/update', scope.row.creator_id)"
                             v-tooltip.top-center="'Edit'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/news/edit/'+scope.row.id">
                  <i class="fa fa-edit"></i></router-link>

                <!-- <router-link  v-if="$store.getters['auth/haveOneOfPermissions'](['news_logs_view'])"
                              v-tooltip.top-center="'Log'"
                              class="btn-info btn-simple btn-link"
                              :to="'/logs/news_' +scope.row.id"><i class="fa fa-eye"></i></router-link> -->

                <a v-if="$store.getters['auth/haveOneOfPermissions'](['news/delete']) ||
                           $store.getters['auth/checkAccessOwner']('restricted_own/news/delete', scope.row.creator_id)"
                   v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
              </template>
            </el-table-column>

          </simple-general-data-table>
        </div>

      </card>


      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this news?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
      <delete-modal :visible="cacheModalVisibility"
                    :message="'Are you sure you want to clear all news cache?'"
                    @close="closeCacheModal()"
                    @confirm="clearCache()">
      </delete-modal>

      <!-- <publish-modal :visible="publishModalVisibility"
                     :message="publishMessage"
                     :publishDates="publishDates"
                     @close="closePublishModal()"
                     @confirm="confirmPublishModal()">
      </publish-modal> -->

      <!-- <push-notification-modal :visible="pushNotificationModalVisibility"
                     :pushNotificationDates="pushNotificationDates"
                     :title="pushNotificationTitle"
                     @close="closePushNotificationModal()"
                     @confirm="confirmPushNotification">
      </push-notification-modal> -->
    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import {Switch as LSwitch} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import FgSelect from "@/components/Inputs/formGroupSelect";
//import PublishModal from "../../../components/Modals/PublishModal";
// import PushNotificationModal from "../../../components/Modals/PushNotificationModal";
import SimpleGeneralDataTable from "../../../components/SimpleGeneralDataTable";


export default {
  components: {
    SimpleGeneralDataTable,
    //PushNotificationModal,
    //PublishModal,
    NLPagination,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    LSwitch,
    GeneralDataTable,
    FgSelect,
  },

  data() {
    return {
      tableColumns: [],
      fixedColumn: null,
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },
      advanceSearchFilter: [],
      exportFilters: [
        {
          key: 'news.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'from',
          class: 'col-6',
          value: null,
        },
        {
          key: 'news.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'to',
          class: 'col-6',
          value: null,
        },
        {
          key: 'news.title',
          type: 'text',
          frontType:'simple',
          label: 'Name',
          class: 'col-6',
          value: null,
        },
        {
          key: 'news.is_active',
          type: 'text',
          frontType:'switch',
          label: 'Is Active',
          class: 'col-6',
          value: null,
        },
      ],
      deleteModalVisibility: false,
      toDeleteId: undefined,
      // publishModalVisibility: false,
      // publishMessage: 'Are you sue you want to publish this news?',
      // publishDates: [],
      // toPublishId: undefined,
      // socialMediaPublish: '',
      componentKey: 0,
      pushNotificationModalVisibility: false,
      pushNotificationDates: [],
      toPushNotificationId: undefined,
      pushNotificationTitle: "",
      filters: {
        is_featured: 0,
      },
      isFeaturedOptions :[
        {
          label:'All',
          value:0
        },
        {
          label:'Featured',
          value:1
        }
      ],
      cacheModalVisibility: false,
    }

  },
  created(){
      this.handlePageComponents();
  },
  watch:{
      $route (to, from){
          this.componentKey++;
          this.handlePageComponents();
      }
  },
  methods: {
    handlePageComponents(){
      this.responsiveViewPort();
      const path = window.location.href;
      this.setAdvanceSearch();
    },
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active
      }
      this.axios.post("news/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "News updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {

      })
    },
    // handleTimelineStatus(row) {
    //   let data = {
    //     'to_timeline': row.to_timeline
    //   }
    //   this.axios.post("news/change-timeline-status/" + row.id, data).then((response) => {
    //     this.$notify({
    //       message: "News updated successfully",
    //       timeout: 1000,
    //       type: 'success'
    //     });
    //   }).catch((error) => {

    //   })
    // },
    clearCache() {
      this.axios.post("news/clear-cache").then((response) => {
        this.$notify({
          message: "Cache Cleared successfully",
          timeout: 1000,
          type: 'success'
        });
        this.closeCacheModal();
      }).catch((error) => {
        console.error(error)
      })
    },

    openCacheModal() {
      this.cacheModalVisibility = true;
    },

    closeCacheModal() {
      this.cacheModalVisibility = false;
    },

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    confirmDeleteModal() {

      let data = {
          'id': this.toDeleteId,
      }
      this.axios.delete("news/delete", {headers: {}, data}).then((response) => {
        this.$notify({
          message: "News deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    // openPushNotificationModal(id, title, pushNotificationDates) {
    //   this.pushNotificationModalVisibility = true;
    //   this.toPushNotificationId = id;
    //   this.pushNotificationTitle = title
    //   this.pushNotificationDates = pushNotificationDates;
    // },

    // closePushNotificationModal() {
    //   this.pushNotificationModalVisibility = false;
    // },
    // confirmPushNotification(title) {
    //   let data = {
    //     'news_id': this.toPushNotificationId,
    //     'notification_body': title
    //   }
    //   this.axios.post("news/push-notification", data).then((response) => {
    //     this.$notify({
    //       message: "Notification added successfully",
    //       timeout: 1000,
    //       type: 'success'
    //     });
    //     this.$refs.table.getData("updateData");

    //   }).catch((error) => {
    //     this.$notify({
    //       message: error.response.data.message,
    //       timeout: 2000,
    //       type: 'danger'
    //     })
    //   })

    //   this.closePushNotificationModal();
    //   this.toPushNotificationId = undefined;
    // },
    // openPublishModal(id, socialMedia, publishDates) {
    //   this.publishModalVisibility = true;
    //   this.toPublishId = id;
    //   this.publishMessage = "Are you sue you want to publish this news on "+socialMedia+" ?"
    //   this.socialMediaPublish = socialMedia;
    //   this.publishDates = publishDates;
    // },

    // closePublishModal() {
    //   this.publishModalVisibility = false;
    // },
    // confirmPublishModal() {
    //   let data = {
    //     'id': this.toPublishId,
    //   }
    //   this.axios.post("news/publish-"+this.socialMediaPublish, data).then((response) => {
    //     this.$notify({
    //       message: "News published on "+this.socialMediaPublish+" successfully",
    //       timeout: 1000,
    //       type: 'success'
    //     });
    //     this.$refs.table.getData("updateData");

    //   }).catch((error) => {
    //     this.$notify({
    //       message: error.response.data.message,
    //       timeout: 2000,
    //       type: 'danger'
    //     })
    //   })

    //   this.closePublishModal();
    //   this.toPublishId = undefined;
    // },
    openModal (name) {
      this.modals[name] = true
    },
    closeModal (name) {
      this.modals[name] = false
    },
    setAdvanceSearch(){
      this.advanceSearchFilter = [
        {
          key: 'news.title',
          type: 'text',
          frontType:'simple',
          label: 'Title',
          class: 'col-md-4 col-sm-6 col-xs-12',
          value: null,
        },
        {
          key: 'news.url',
          type: 'text',
          frontType:'simple',
          label: 'Url',
          class: 'col-md-4 col-sm-6 col-xs-12',
          value: null,
        },
        {
          key: 'news.is_active',
          type: 'text',
          frontType:'switch',
          label: 'Is Active',
          class: 'col-md-4 col-sm-6 col-xs-12',
          value: null,
        },
        {
          key: 'news.category_id',
          type: 'multiple-select',
          url: 'categories/paginated-categories',
          frontType:'paginated-select',
          strategy: '',
          label: 'Category',
          class: 'col-md-4 col-sm-6 col-xs-12',
          multiple: true ,
          value: null,
        },
        {
          key: 'news.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'Created after',
          class: 'col-md-6 col-sm-12',
          value: null,
        },
        {
          key: 'news.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'Created before',
          class: 'col-md-6 col-sm-12',
          value: null,
        },
        {
          key: 'news.date',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'after date',
          class: 'col-md-6 col-sm-12',
          value: null,
        },
        {
          key: 'news.date',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'before date',
          class: 'col-md-6 col-sm-12',
          value: null,
        },


      ];
    },
    responsiveViewPort(){
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            this.fixedColumn = null;
            this.tableColumns = [
                {label: 'Title', value: 'title', minWidth: '200', align: 'center'},
                {label: 'Category', value: 'category', minWidth: '200', align: 'center'},
            ];
        }else{
            this.fixedColumn = 'right';
            this.tableColumns = [
                {label: 'Title', value: 'title', minWidth: '200', align: 'center'},
                {label: 'URL', value: 'url', minWidth: '200', align: 'center'},
                {label: 'Category', value: 'category', minWidth: '200', align: 'center'},
                {label: 'Date', value: 'date', minWidth: '200', align: 'center'},
            ];
        }
    },
    applyFilter() {
      this.$refs.table.filters = {...this.filters};
      if (this.filters.is_featured) {
        this.$refs.table.createDragAndDropSorting();
      } else {
        this.$refs.table.destroyDragAndDropSorting();
      }
      this.$refs.table.getData("updateData");
    },
    async reorder(data) {
      try {
        let body = {};
        let orderData = [];
        data.forEach((row, i) => {
          orderData.push({"id": row.id, "sort_order": i + 1})
        });
        body = {
          data: orderData,
        }

        await this.axios.post("news/re-order", body);

        this.$notify({
          message: "order updated successfully",
          timeout: 1000,
          type: 'success'
        });

      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      }
    },

  },

}
</script>
