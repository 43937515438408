<template>

  <div class="row" ref="groupForm">

    <div class="col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">{{ formTitle }}</h4>
          </div>

          <div class="card-body">
            <ValidationProvider
              vid="parent_id"
              rules=""
              name="Parent"
              v-slot="{ passed, failed,errors }">
              <fg-select
                name="parent_id"
                size="large"
                filterable
                clearable
                placeholder="Parent"
                :error="failed ? errors[0]: null"
                :input-classes="'select-default'"
                :label="'Parent'"
                :list="categoryList"
                :listItemLabel="'name'"
                :listItemValue="'id'"
                v-model="formData.parent_id">
              </fg-select>
            </ValidationProvider>
            <ValidationProvider vid="name" rules="required" name="The name" v-slot="{ passed, failed,errors }">
              <fg-input type="text" :error="failed ? errors[0]: null" label="Category Name *" name="name" fou @keyup="generateURL" v-model="formData.name"> </fg-input>
            </ValidationProvider>
            <ValidationProvider vid="url" rules="required" name="The url" v-slot="{ passed, failed,errors }">
              <fg-input type="text" :error="failed ? errors[0]: null" label="URL *" name="url" fou v-model="formData.url"> </fg-input>
            </ValidationProvider>
            <ValidationProvider
              vid="color"
              rules=""
              name="The Color"
              v-slot="{ passed, failed,errors }">
              <fg-input type="color"
                        :error="failed ? errors[0]: null"
                        label="Color"
                        name="color"
                        fou
                        v-model="formData.color">
              </fg-input>
            </ValidationProvider>
            <div class="form-group pt-4">
              <label>Is Active</label>&nbsp;
              <l-switch v-model="formData.is_active">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
              </l-switch>
            </div>
            <div class="form-group pt-4">
              <label>Show in menu</label>&nbsp;
              <l-switch v-model="formData.in_nav">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
              </l-switch>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/categories/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Switch as LSwitch} from "@/components";
import FgSelect from "@/components/Inputs/formGroupSelect";

extend("required", {
  message: "{_field_} is required"
});

export default {
  components: {
    LSwitch,
    FgSelect,
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        parent_id: '',
        name: "",
        url: "",
        color: "",
        is_active: true,
        in_nav: false
      },
      categoryList: [],
      colorList: [],
    };
  },
  created(){
    const path = window.location.href;
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.groupForm
    });
    this.getBuilderData();
  },


  methods: {
    getBuilderData() {
      let data = {}
      this.axios.post("categories/builder", data).then((response) => {
        this.categoryList = response.data.categories;
        this.colorList = response.data.colors;

        this.id = this.$route.params['id'];

        if (this.id !== undefined) {
          this.editMode = true;
          this.formTitle = "Edit Category";
          this.getCategory();
        } else {
          this.editMode = false;
          this.formTitle = "Add Category";
          this.loader.hide();
        }

      }).catch((error) => {
        console.error(error);
      });
    },
    getCategory() {
      let data = {
        'id' : this.id,
      };
      this.axios.post("categories/get", data).then((response) => {
        this.formData = response.data;
        this.formData.parent_id= this.formData.parent_id == 0 ? '' : this.formData.parent_id;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Category Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },

    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      this.formData.parent_id= this.formData.parent_id == '' ? 0 : this.formData.parent_id;

      if (this.editMode === true) {
        request = this.axios.put("categories/update/" + this.id, this.formData);
        successMessage = "Category Updated Successfully";
      } else {
        request = this.axios.post("categories/create", this.formData);
        successMessage = "Category Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/categories/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {

        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    generateURL() {

      let newUrl = '';
      if (this.formData.name) {
        newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "-");
      }

      this.formData.url = newUrl;
    }

  }
}
</script>

<style>
</style>
