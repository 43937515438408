<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Category List</h4>
          <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['categories/create'])" :to="'/categories/create'" class="btn btn-info btn-wd" wide>
            Add New
            <span class="btn-label">
              <i class="fa fa-plus"></i>
            </span>
          </router-link>
        </div>
        <div>
          <general-data-table
            ref="table"
            :row-key="'id'"
            :key="componentKey"
            :api-url="'categories/index'"
            @reorder="reorder($event)">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :prop="column.value"
                             :sortable="column.sortable"
                             :label="column.label">
              <template slot-scope="scope">
                <div v-if="column.value === 'color'">
                  <span :style="{padding: '35px', backgroundColor: scope.row[column.value] }"></span>
                </div>

                <span v-else>{{ scope.row[column.value] }}</span>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/haveOneOfPermissions'](['categories/change-active-status']) ||
                                $store.getters['auth/checkAccessOwner']('restricted_own/categories/change-active-status', props.row.creator_id)"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>
            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <template>
                  <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['categories/update']) ||
                                     $store.getters['auth/checkAccessOwner']('restricted_own/categories/update', scope.row.creator_id)"
                               v-tooltip.top-center="'Edit'"
                               class="btn-warning btn-simple btn-link"
                               :to="'/categories/edit/'+scope.row.id">
                    <i class="fa fa-edit"></i>
                  </router-link>

                  <a v-if="$store.getters['auth/haveOneOfPermissions'](['categories/delete']) ||
                           $store.getters['auth/checkAccessOwner']('restricted_own/categories/delete', scope.row.creator_id)"
                     v-tooltip.top-center="'Delete'"
                     class="btn-danger btn-simple btn-link"
                     @click="openDeleteModal(scope.row.id)">
                    <i class="fa fa-times"></i></a>
                </template>


              </template>
            </el-table-column>
          </general-data-table>
        </div>
      </card>
      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this category?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
    </div>
  </div>
</template>

<script>
import {DeleteModal as DeleteModal, Switch as LSwitch} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {TableColumn} from "element-ui";

export default {
  components: {
    GeneralDataTable,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    LSwitch
  },

  data() {

    return {
      tableColumns: [
        {label: 'Category Name', value: 'name', minWidth: '200', align: 'center'},
        {label: 'Category URL', value: 'url', minWidth: '200', align: 'center'},
        {label: 'Category Color', value: 'color', minWidth: '200', align: 'center'},
      ],
      fixedColumn: null,
      deleteModalVisibility: false,
      toDeleteId: undefined,
      componentKey: 0,
    }
  },
  created(){
    this.responsiveViewPort();
    const path = window.location.href;
  },
  mounted(){
    this.$refs.table.createDragAndDropSorting();
  },
  watch:{
    $route (to, from){
      const path = window.location.href;
      this.componentKey++;
    }
  },
  methods: {
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active,
      }
      this.axios.post("categories/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "Category updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {

      })
    },
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    async confirmDeleteModal() {
      try {
        let data = {
          'id': this.toDeleteId
        }
        await this.axios.delete("categories/delete",{headers: {}, data});
        this.$refs.table.getData("updateData");
        this.$notify({
          message: "Category deleted successfully",
          timeout: 1000,
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      } finally {
        this.closeDeleteModal();
        this.toDeleteId = undefined;
      }
    },
    responsiveViewPort(){
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.fixedColumn = null;
      }else{
        this.fixedColumn = 'right';
      }
    },
    async reorder(data) {
      try {
        let orderData = [];
        data.forEach((row, i) => {
          orderData.push({"id": row.id, "sort_order": i + 1})
        });
        let body = {
          'data':orderData
        }
        await this.axios.post("categories/re-order", body);

        this.$notify({
          message: "order updated successfully",
          timeout: 1000,
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      }
    },

  },
}
</script>

