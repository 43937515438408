<template>

  <div class="row" ref="NewsForm">
    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-8 col-sm-12">
                <ValidationProvider
                  vid="category_id"
                  rules="required"
                  name="The Category"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="category_id"
                    size="large"
                    filterable
                    clearable
                    placeholder="Category *"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'Category *'"
                    :list="categoryList"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.category_id">
                  </fg-select>
                </ValidationProvider>
                <ValidationProvider
                  vid="Types"
                  rules="required"
                  name="The Types"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="type"
                    size="large"
                    placeholder="Types *"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'Types *'"
                    :list="typeOptions"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.type">
                  </fg-select>
                </ValidationProvider>
                <ValidationProvider
                  vid="title"
                  rules="required"
                  name="The Title"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="title *"
                            name="title"
                            fou
                            @keyup="generateUrlAndMetaTitleFields"
                            v-model="formData.title">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="excerpt"
                  rules="required"
                  name="The Excerpt"
                  v-slot="{ passed, failed,errors }">
                  <fg-text type="text"
                           :error="failed ? errors[0]: null"
                           label="excerpt *"
                           name="excerpt"
                           @keyup="generateMetaDesc"
                           fou
                           v-model="formData.excerpt">
                  </fg-text>
                </ValidationProvider>

                <div class="form-group">
                  <label>Featured Image</label>
                  <el-tooltip placement="right" v-if="getBannerImageInfo()">
                    <div slot="content">{{ getBannerImageInfo() }}</div>
                    <span style="margin: 0 5px;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                               class="bi bi-question-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path
                          d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                      </svg>
                      </span>
                  </el-tooltip>

                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    v-model="formData.featured_image"
                  >
                  </prime-uploader>
                </div>
                <div class="form-group">
                  <label>Content</label>
                  <editor
                    v-model="formData.content"
                    :api-key="editorKey"
                    :init='editorConfig'
                    :key="editorKeyComponent"
                  />
                </div>
                <div class="form-group">
                  <el-tooltip placement="right" v-if="getBannerVideoInfo()">
                    <div slot="content">{{ getBannerVideoInfo() }}</div>
                    <span style="margin: 0 5px;">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <ValidationProvider
                    vid="video_link"
                    rules=""
                    name="Video link"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :error="failed ? errors[0]: null"
                              label="Video link"
                              name="video_link"
                              fou
                              v-model="formData.video_link">
                    </fg-input>
                  </ValidationProvider>
                  <div v-html="formData.video_link"></div>
                </div>

                <div class="form-group">
                  <label>Gallery</label>
                  <el-tooltip placement="right" v-if="getBannerImageInfo()">
                    <div slot="content">{{ getBannerImageInfo() }}</div>
                    <span style="margin: 0 5px;">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                      </svg>
                    </span>
                  </el-tooltip>

                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="20"
                    v-model="formData.gallery"
                  >
                  </prime-uploader>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="col-md-12 col-sm-12 extra-fields">
                  <h4>Publish Status</h4>
                  <div class="col-md-12 col-sm-12">
                    <div v-if="$store.getters['auth/haveOneOfPermissions'](['news/change-date']) ||
                                     (editMode && $store.getters['auth/checkAccessOwner']('restricted_own/news/change-date', formData.creator_id))">
                      <label class="card-label">News Date</label>
                      <fg-input v-model="formData.date"
                                value-format="YYYY-MM-DD HH:MM:SS"
                                type="datetime-local"
                                placeholder="Chose News publish date"
                                readonly="true"
                      >
                      </fg-input>
                      <label class="card-label">Start Date</label>
                      <fg-input v-model="formData.start_date"
                                @change="startDateChangeEvent()"
                                value-format="YYYY-MM-DD HH:MM:SS"
                                type="datetime-local"
                                placeholder="Chose News publish date"
                      >
                      </fg-input>
                      <label class="card-label">End Date</label>
                      <fg-input v-model="formData.end_date"
                                value-format="YYYY-MM-DD HH:MM:SS"
                                type="datetime-local"
                                placeholder="Chose News expire date"
                      >
                      </fg-input>
                    </div>
                    <div class="form-group pt-4">
                      <label>Is Active</label>&nbsp;
                      <l-switch v-model="formData.is_active">
                        <i class="fa fa-check" slot="on"></i>
                        <i class="fa fa-times" slot="off"></i>
                      </l-switch>
                    </div>

                    <div class="form-group pt-4">
                      <label>Is Featured</label>&nbsp;
                      <l-switch v-model="formData.is_featured">
                        <i class="fa fa-check" slot="on"></i>
                        <i class="fa fa-times" slot="off"></i>
                      </l-switch>
                    </div>
                    <div class="form-group pt-4">
                      <label>Is Hightligh</label>&nbsp;
                      <l-switch v-model="formData.is_highligh">
                        <i class="fa fa-check" slot="on"></i>
                        <i class="fa fa-times" slot="off"></i>
                      </l-switch>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-sm-12 extra-fields">
                  <div class="col-md-12">
                    <paginated-select
                      :labelTitle="'Related News'"
                      :isMultiple="true"
                      @select-changed="setRelatedNews"
                      :apiUrl="'news/paginatedNews'"
                      :initData="formData.related_news"
                      :key="paginatedCompKey"
                    >
                    </paginated-select>
                  </div>
                </div>

                <div class="col-md-12 col-sm-12 extra-fields">
                  <h4>SEO Settings</h4>

                  <div class="col-md-12 col-sm-12">
                    <ValidationProvider
                      vid="url"
                      rules="required"
                      name="The URL"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="url"
                                name="url"
                                fou
                                v-model="formData.url">
                      </fg-input>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="meta_title"
                      rules=""
                      name="The Meta Title"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Meta Title"
                                name="meta_title"
                                fou
                                v-model="formData.meta_title">
                      </fg-input>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="meta_description"
                      rules=""
                      name="The Meta Description"
                      v-slot="{ passed, failed,errors }">
                      <fg-text type="text"
                               :error="failed ? errors[0]: null"
                               label="Meta Description"
                               name="meta_description"
                               fou
                               v-model="formData.meta_description">
                      </fg-text>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="meta_keywords"
                      rules=""
                      name="The Meta Keywords"
                      v-slot="{ passed, failed,errors }">
                      <fg-text type="text"
                               :error="failed ? errors[0]: null"
                               label="Meta Keywords"
                               name="meta_keywords"
                               fou
                               v-model="formData.meta_keywords">
                      </fg-text>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/news/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>
    <quick-adding-modal
      :visible="addingModalVisibility"
      :url="addingTypeUrl"
      :title="addingModalTitle"
      @close="closeAddingModal()"
    >
    </quick-adding-modal>
  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select, TableColumn, Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {PrimeUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import GeneralDataTable from "@/components/GeneralDataTable";
import vSelect from "vue-select";
import QuickAddingModal from "@/pages/news/news/QuickAddingModal"
import PaginatedSelect from "@/components/paginatedSelect";
import PaginatedSelectFilter from "@/components/FilterShared/PaginatedSelectFilter";
import PaginatedSelectForm from "../../../components/Inputs/PaginatedSelectForm";

extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    PaginatedSelectForm,
    PaginatedSelectFilter,
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    [TableColumn.name]: TableColumn,
    LSwitch,
    GeneralDataTable,
    PrimeUploader,
    'editor': Editor,
    PaginatedSelect,
    vSelect,
    QuickAddingModal
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      paginatedCompKey: 0,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      editorConfig: this.getEditorConfig(),
      imagesExtensions: this.getImagesExtensions(),
      videoExtensions: this.getVideoExtensions(),
      addingModalVisibility: false,
      addingTypeUrl: '',
      addingModalTitle: '',
      typeOptions: [
        {id: "article", label: "article"},
        {id: "video", label: "video"},
        {id: "gallery", label: "gallery"},
      ],
      formData: {
        category_id: "",
        type: "",
        title: "",
        excerpt: "",
        featured_image: null,
        content: "",
        video_link: "",
        gallery: [],

        date: "",
        start_date: null,
        end_date: null,
        is_active: true,
        is_featured: false,
        is_highligh: false,
        related_news: [],

        url: "",
        meta_title: "",
        meta_keywords: "",
        meta_description: ""
      },

      categoryList: [],
      newsList: [],
      search: '',
      offset: 0,
      limit: 5,
      newsCount: 0,
      selectKey: 0,
      editorKeyComponent: 0,
      paginatedSelectItems:{},
      paginationSelectType: '',
    };
  },
  created() {
    const path = window.location.href;
  },
  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    }),
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.newsForm
    });

    this.getBuilderData();
    this.editorConfig.content_style += ' body{direction: rtl;text-align:right;}';
    this.editorKeyComponent++;

  },
  methods: {
    getNews() {
      this.axios.get("news/get/" + this.id).then((response) => {
        this.formData = response.data;
        this.formData.related_news = response.data.relatedNewsBack;
        this.selectKey++;
        this.formData.gallery = response.data.gallery;
        this.formData.featured_image = response.data.featured_image;
        this.vueDate();
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "News Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    getBuilderData() {
      this.axios.post("news/builder").then((response) => {
        this.categoryList = response.data.categories;
        this.id = this.$route.params['id'];
        if (this.id !== undefined) {
          this.editMode = true;
          this.formTitle = "Edit News";
          this.getNews();
        } else {
          this.editMode = false;
          this.formTitle = "Add News";
          this.formData.date = this.getDateNow();
          this.loader.hide();
        }

      }).catch((error) => {
        console.error(error);
      });
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      if (this.formData.featured_image == null ) {
        this.$notify({
          message: "You have to add featured image",
          timeout: 3000,
          type: 'danger'
        });
        this.submitting = false;
        return;
      }
      let formData = {...this.formData};
      if (formData.related_news && formData.related_news.length) {
        formData.related_news = formData.related_news.map(news => news.id);
      }

      let newsTypeErrors = this.checkNewsTypeErrors();
      if (newsTypeErrors['hasError'] === true) {
        this.$notify({
          message: newsTypeErrors['errorMessage'],
          timeout: 3000,
          type: 'danger'
        });
        this.submitting = false;
        return;
      }

      formData.start_date = this.laravelDate(formData.start_date);
      formData.end_date = this.laravelDate(formData.end_date);
      formData.date = this.laravelDate(formData.date);

      if (this.editMode === true) {
        request = this.axios.put("news/update/" + this.id, formData);
        successMessage = "News Updated Successfully";
      } else {
        request = this.axios.post("news/create", formData);
        successMessage = "News Added Successfully";
      }
      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/news/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {

        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    getBannerImageInfo() {
      if (this.mediaInfo && this.mediaInfo["news_image"]) {
        return this.mediaInfo["news_image"];
      } else {
        return "";
      }
    },
    generateUrlAndMetaTitleFields() {
      let newUrl = '';
      if (this.formData.title) {
        this.formData.meta_title = this.formData.title;
        newUrl = this.formData.title.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "-");
      }

      if (!this.editMode){
        this.formData.url = newUrl;
      }
    },
    generateMetaDesc() {
      this.formData.meta_description = this.formData.excerpt;
    },
    getBannerVideoInfo() {
      if (this.mediaInfo && this.mediaInfo["news_video"]) {
        return this.mediaInfo["news_video"];
      } else {
        return "";
      }
    },
    startDateChangeEvent() {
      if (!this.formData.start_date) {
        this.formData.date = this.getDateNow();
      } else {
        this.formData.date = this.formData.start_date;
      }
    },
    getDateNow() {
      const d = new Date;
      return [
          d.getFullYear(),
          ("0" + (d.getMonth() + 1)).slice(-2),
          ("0" + d.getDate()).slice(-2)
        ]
          .join('-') + 'T' +
        [
          ("0" + d.getHours()).slice(-2),
          ("0" + d.getMinutes()).slice(-2),
          ("0" + d.getSeconds()).slice(-2)
        ]
          .join(':');
    },
    vueDate() {
      this.formData.start_date = (this.formData.start_date) ? this.formData.start_date.replace(' ', 'T') : this.formData.start_date;
      this.formData.end_date = (this.formData.end_date) ? this.formData.end_date.replace(' ', 'T') : this.formData.end_date;
      this.formData.date = (this.formData.date) ? this.formData.date.replace(' ', 'T') : this.formData.date;
    },
    laravelDate(date) {
      return date ? date.replace('T', ' ') : date;
    },
    openAddingModal(addingTypeUrl, title, type) {
      this.addingTypeUrl = addingTypeUrl;
      this.addingModalTitle = title;
      this.addingModalVisibility = true;
      this.paginationSelectType = type
    },
    closeAddingModal() {
      this.addingModalVisibility = false;
      if (this.paginationSelectType === 'source'){
        this.paginatedSelectItems.source.sourceKey++;
      }else if (this.paginationSelectType === 'author'){
        this.paginatedSelectItems.author.authorKey++;
      } else if (this.paginationSelectType === 'tag'){
        this.paginatedSelectItems.tag.tagKey++;
      }
    },
    setRelatedNews(selectedNews) {
      this.formData.related_news = selectedNews
    },
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    checkNewsTypeErrors() {
      let errorsArray = [];
      errorsArray['hasError'] = false;
      errorsArray['errorMessage'] = '';
      if (this.formData.type.includes('gallery') && this.formData.gallery.length < 1) {
        errorsArray['hasError'] = true;
        errorsArray['errorMessage'] = 'You have to add gallery or remove gallery from news type';
      }
      return errorsArray;
    },

    setSelectedCase(selectedCase){
      this.formData.cases = selectedCase;
    },
  }
}
</script>

<style>

.extra-fields {
  box-shadow: rgb(235 232 232) 1px 1px 20px 0px, rgb(235 232 232) -1px -1px 20px 0px;
  padding: 0;
  padding-bottom: 3px;

}

.extra-fields > h4 {
  padding: 20px;
}

.style-chooser .vs__selected {
  border-radius: 3px !important;
  border-color: #23CCEF;
  background-color: white;
  border-radius: 2px;
  color: #23CCEF;
}

.style-chooser .vs__deselect {
  background-color: transparent;
  color: #23CCEF;
  opacity: 1;
  font-size: 12px;
}

.style-chooser .vs__dropdown-option--selected {
  color: #23CCEF;
}

.style-chooser .prev-next {
  margin-top: 15px;
}

.style-chooser .prev-next button {
  margin-right: 15px;
  margin-left: 10px;
}
.invalid-label{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  text-transform: initial !important;
}
.select-style{
  flex-basis: 85%;
}
.paginated-select-style{
  margin: 0px 10px;
}
</style>
